.slider-container {
  position: relative;
  margin-top: 5rem;
}
.slider-container .swiper {
  width: 90%;
  height: 12rem;
  position: unset;
}
.slider-container .swiper-slide{
    background: #ffffff;
    display: flex;
    border-radius: 10px;
    padding: 1rem;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
}

.slider-container .swiper-button-prev{
    left: 2rem;
    color:black;
}
.slider-container .swiper-button-next{
    right: 2rem;
    color:black;
}
.name{
    display: flex;
    flex-direction: column;
}
.name>:nth-child(1){
    font-size: 1.4rem;
}
.name>:nth-child(2){
    font-size: 0.7rem;
}
.left-s>:nth-child(2){
    font-size: 2rem;
    font-weight: bold;
}
.left-s>:nth-child(3){
    font-size: 0.6rem;
    /* font-weight: bold; */
    border: 1px solid var(--black);
    padding: 5px;
    width: max-content;
    border-radius: 15px;
}