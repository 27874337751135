 .orders header {
    height: 5%;
    margin-bottom: 30px;
    margin: 30px  auto;
    width:80%;
  }
   /* header > h3 {
    font-size: 25px;
    color: #4E5150;
    font-weight: 500;
  } */
  .orders .linkCart{
    text-decoration: none;
    color: rgb(255, 255, 255);
    width: 300px;
    background: #aa0ef3;
    font-weight: bolder;
    padding: 1rem;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
  }
  .orders  .main {
    height: 85%;
    display: flex;
    column-gap: 100px;
    margin: 0 auto;
    width: 70%;
  }
  .orders  .main .checkout-form {
    width: 50%;
  }
   .orders .main .checkout-form form h6 {
    font-size: 12px;
    font-weight: 500;
  }
   .orders .main .checkout-form form .form-control {
    margin: 10px 0px;
    position: relative;
    
  }
   .orders .main .checkout-form form .form-control label:not([for=checkout-checkbox]) {
    display: block;
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 2px;
  }
   .orders .main .checkout-form form .form-control input:not([type=checkbox]) {
    width: 100%;
    padding: 10px 10px 10px 40px;
    border-radius: 10px;
    outline: none;
    border: 0.2px solid #4e515085;
    font-size: 15px;
    font-weight: 700;
  }
  .orders  .main .checkout-form form .form-control input:not([type=checkbox])::placeholder {
    font-size: 13px;
    font-weight: 500;
  }
   .orders .main .checkout-form form .form-control label[for=checkout-checkbox] {
    font-size: 16px;
    font-weight: 500;
    line-height: 10px;
  }
   .orders .main .checkout-form form .form-control > div {
    position: relative;
  }
   .orders .main .checkout-form form .form-control > div span.fa {
    position: absolute;
    top: 50%;
    left: 0%;
    transform: translate(15px, -50%);
  }
   .orders .main .checkout-form form .form-group {
    display: flex;
    column-gap: 25px;
  }
   .orders .main .checkout-form form .checkbox-control {
    display: flex;
    align-items: center;
    column-gap: 10px;
  }
   .orders .main .checkout-form form .form-control-btn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
   .orders .main .checkout-form form .form-control-btn button {
    padding: 10px 25px;
    font-size: 10px;
    color: #000000;
    background: #4af288;
    border: 0;
    border-radius: 7px;
    letter-spacing: 0.5px;
    font-weight: 200;
    cursor: pointer;
  }
   .orders .main .checkout-details {
    width: 40%;
  }
   .orders .main .checkout-details .checkout-details-inner {
    background: #F2F2F2;
    border-radius: 10px;
    padding: 20px;
  }
   .orders .main .checkout-details .checkout-details-inner .checkout-lists {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    margin-bottom: 40px;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.63);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(9.6px);
    -webkit-backdrop-filter: blur(9.6px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 1rem;
  }
   .orders .main .checkout-details .checkout-details-inner .checkout-lists .card {
    width: 100%;
    display: flex;
    column-gap: 15px;
  }
   .orders .main .checkout-details .checkout-details-inner .checkout-lists .card .card-image {
    width: 35%;
  }
   .orders .main .checkout-details .checkout-details-inner .checkout-lists .card .card-image img {
    width: 80px;
    height:80px;
    object-fit: contain;
    border-radius: 10px;
  }
   .orders .main .checkout-details .checkout-details-inner .checkout-lists .card .card-details {
    display: flex;
    flex-direction: column;
  }
   .orders .main .checkout-details .checkout-details-inner .checkout-lists .card .card-details .card-name {
    font-size: 16px;
    font-weight: 500;
  }
   .orders .main .checkout-details .checkout-details-inner .checkout-lists .card .card-details .card-price {
    font-size: 14px;
    font-weight: 500;
    color: #9420ed;
    font-weight: bolder;
    margin-top: 5px;
  }
   .orders .main .checkout-details .checkout-details-inner .checkout-lists .card .card-details .card-price span {
    color: #ed5d4a;
    text-decoration: line-through;
    margin-left: 10px;
  }
   .orders .main .checkout-details .checkout-details-inner .checkout-lists .card .card-details .card-wheel {
    margin-top: 17px;
    border: 0.2px solid #4e515085;
    width: 90px;
    padding: 8px 8px;
    border-radius: 10px;
    font-size: 12px;
    display: flex;
    justify-content: space-between;
  }
   .orders .main .checkout-details .checkout-details-inner .checkout-lists .card .card-details .card-wheel button {
    background: #E0E0E0;
    color: #828282;
    width: 15px;
    height: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
    cursor: pointer;
    border-radius: 3px;
    font-weight: 500;
  }
   .orders .main .checkout-details .checkout-details-inner .checkout-shipping,  .main .checkout-details .checkout-details-inner .checkout-total {
    display: flex;
    font-size: 20px;
    padding: 5px 0px;
    align-items: center;
    justify-items: center;
    /* border-top: 1px solid #BDBDBD; */
    justify-content: space-around;

    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.63);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(9.6px);
    -webkit-backdrop-filter: blur(9.6px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    /* gap: 1rem; */
    margin-top: 1rem;
  }
   .orders .main .checkout-details .checkout-details-inner .checkout-shipping p,  .main .checkout-details .checkout-details-inner .checkout-total p {
    font-size: 20px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-items: center;
    justify-content: space-around;
    font-weight: bolder;
  }


  .orders .checkout-details .checkout-details-inner .checkout-total{
    background: none;
  }
  .orders .checkout-details .pay-button{
    margin: auto;
    width: 30%;
  }
  .orders .checkout-details .pay-button button{
    display:flex;
    background: rgb(4, 120, 50);
    padding: 1rem;
    width: 100%;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    justify-content:center;
    align-items:center;
    justify-items: center;
    color: #dcd7e0;
    font-weight: bolder;
    font-size: 22px;
  }

  
  @media screen and (max-width: 1024px) {
     .orders .main {
      column-gap: 70px;
    }
  }
  @media screen and (max-width: 768px) {
     
     .orders .main {
      flex-direction: column-reverse;
      height: auto;
      margin-bottom: 50px;
    }
     .orders .main .checkout-form {
      width: 100%;
      margin-top: 35px;
    }
     .orders .main .checkout-details {
      width: 100%;
    }

  }