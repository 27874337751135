
.avatar-box {
  width: 60px;
  height: 60px;
  aspect-ratio: 1/1;
  background-image: linear-gradient(160deg, #000000 5%, #b615f6, #ffd372);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
  cursor: pointer;
}
.avatar-profile-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: 3px;
  border: 3px solid white;
}
.carousel-status {
  padding: 16px 0px;
}
.carousel-body {
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  justify-content: space-around;
  align-items: center;
  /* overflow-y: none; */
  height: 120px;
  /* border: 1px solid rgba(219, 219, 219); */
  background: transparent;
  border-radius: 50px;
  position: relative;
  scroll-behavior: smooth;
  padding-left: 40px;
  margin: auto;
  width: 90%;
  
}
.carousel-body::-webkit-scrollbar {
  display: none;
}
.avatar-detail {
  margin-left: 20px;
  text-align: center;
}
.avatar-name {
  font-size: 12px;
  color: #262626;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 60px;
}
.check {
  position: relative;
  margin: 1rem auto;
  width: 90%;
}
.arrow-btn {
  width: 25px;
  height: 25px;
  position: absolute;
  background-color: #ffffff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.8;
  z-index: 999;
  cursor: pointer;
  border: 2px solid black;
}
.arrow-btn img {
  width: 10px;
  height: 10px;
}
.left-icon {
  left: 10px;
  top: 40px;
}
.right-icon {
  right: 10px;
  top: 40px;
}
