.cart-section{
    margin: 0 auto;
    width: 70%;
    /* background: black; */
    display: flex;
}
.cart-section .products-section{
    width: 70%;
    /* background: red; */
    padding: 1rem;
    border-radius: 10px;
}
.cart-section .total-payment-section{
    width: 30%;
    background: transparent;
  /* background: radial-gradient(circle, rgba(254,254,254,0.3905063490239846) 0%, rgb(187, 150, 236) 100%); */
    padding: 1rem;
    border-radius: 10px;
    margin-top:1rem;
    display: grid;
    grid-template-columns: repeat(1,1fr);
    gap: 1rem;
}

.cart-section .products-section .product-cover{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    /* background: rgb(237, 232, 244); */
    align-items: center;
    border-radius: 5px;
    padding: 1rem;
}

.cart-product-price .product-quantity{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    justify-items: center;
    /* background: red; */
    padding: 1rem;
  }
  .cart-section .decrease,  .increase{
    background-color: #630463;
    padding: .3rem;
    border-radius: 50px;
    cursor: pointer;
    color: white;
    display: flex;
    align-items: center;
  }
  .cart-section .product-amount{
    border-radius: 5%;
    font-size: 16px;
    font-weight: bolder;
    /* border: 1px solid gray; */
  }

  .cart-section .product-cart-image img{
    object-fit: contain;
    width: 150px;
    height: 100px;
    background: transparent;
    border-radius: 5px;
  }
  .cart-section .cart-product-details{
    text-align: center;
    color: blueviolet;
  }
  .cart-section .p-status{
    color: green;
  }
  .cart-section  .total-product-price{
    color: blueviolet;
    font-size: 25px;
    font-weight: bolder;
    text-align: center;
    display: flex;
    justify-content: space-between;
  }
  .cart-section .product-delete{
    color: white;
    font-size: 20px;
    background: rgb(73, 73, 73);
    font-weight: bolder;
    text-align: center;
    padding: 1rem;
    display: flex;
    align-items: center;
    border-radius: 50px;
    cursor: pointer;
  }
  .clear-my-cart{
    padding:1rem;
    margin-top:1rem;
    background:black;
    color:white;
    border-radius: 10px;
    width: 150px;
    display: flex;
    align-items: center;
    text-align: center;
    align-content: center;
    cursor: pointer;
  }

  .total-payment-section .SubTotal{
    background: rgba(255, 255, 255, 0.63);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(9.6px);
    -webkit-backdrop-filter: blur(9.6px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    display: flex;
    justify-content: space-around;
    color:#630463;
    font-size: 16px;
    font-weight: bolder;
    align-items: center;
    height:100px;
  }
  .total-payment-section .SubTotal .Amount{
    font-size: 21px;
  }

  .total-payment-section .Info{
    background: rgba(255, 255, 255, 0.63);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(9.6px);
    -webkit-backdrop-filter: blur(9.6px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    text-align: center;
    color:#630463;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    font-weight: bolder;
    align-items: center;
    justify-content: center;
    height: 200px;
  }
 .total-payment-section .Info .Data{
  font-size: 15px;
  color: gray;
}
.cart-section .total-payment-section .Info button{
  margin-top: 1rem;
  padding: 1rem;
  background: #630463;
  width: 100%;
  color: #ffffff;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: bolder;
  cursor: pointer;
  border-radius: 10px;
}




