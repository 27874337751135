.Main {
  padding: 0 2rem;
  margin-top: 10rem;
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
}
.twinSides {
  display: grid;
  grid-template-rows: 1fr 1fr;
}
.text1 {
  text-transform: uppercase;
  font-size: 1.5rem;
  width: min-content;
  display: block;
  font-weight: 600;
}
.text2 {
  width: min-content;
  display: flex;
  flex-direction: column;
}

.text2 > :first-child {
  font-weight: 800;
  font-size: 2.5rem;
}
.text2 > :nth-child(2) {
  display: block;
}

.mainWrapper {
  display: flex;
  align-items: baseline;
  justify-content: center;
  position: relative;
}

.mainWrapper img {
  width: 30rem;
  position: absolute;
  border-radius: 50%;
  bottom: 0;
}
.blueCircle {
  width: 30rem;
  height: 30rem;
  border-radius: 50%;
  z-index: -1;
  position: absolute;
  bottom: 0;
  background: white;
}
.cart2 {
  position: absolute;
  bottom: 20%;
  right: 5%;
  display: flex;
  gap: 1rem;
  align-items: center;
}

.cart2 > svg {
  width: 50px;
  height: 50px;
  background: #ffffff;
  padding: 10px;
  border-radius: 50%;
  border: 6px solid var(--black);
}
.signup {
  display: flex;
  gap: 1rem;
  align-items: center;
  background: rgb(255, 255, 255);
  padding: 10px;
  font-size: 0.8rem;
  border-radius: 15px;
  box-shadow: var(--shadow1);
}
.signup > :first-child {
  display: block;
  width: 6rem;
}
.signup > :nth-child(2) {
  display: flex;
  border-radius: 50%;
  border: 1px solid rgb(50, 49, 49);
  width: 40px;
  height: 40px;
  padding: 5px;
  align-items: center;
  justify-content: center;
}

.traffic {
  display: flex;
  flex-direction: column;
  text-align: right;
}
.traffic > :first-child {
  font-weight: 800;
  font-size: 2.5rem;
}
.customers {
  display: flex;
  flex-direction: column;
  text-align: right;
}
.customers > :first-child {
  font-weight: 400;
  font-size: 2.5rem;
}
