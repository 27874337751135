@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400;0,500;0,600;1,400;1,500;1,600&display=swap");

.H-Navigation {
  color: var(--body-font);
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
  margin: 0 auto;
  width: 80%;
  z-index: 1000;
  align-items: center;
  -webkit-box-shadow: rgba(0, 0, 0, 0.033) 0px 4px 12px;
  -moz-box-shadow: rgba(0, 0, 0, 0.033) 0px 4px 12px;
  /* box-shadow: 0 4px 6px -6px #222; */
  background: #f5f5f7;
  border-radius: 50px;
  box-shadow: rgba(0, 0, 0, 0.033) 0px 4px 12px;
}
.menu {
  justify-content: flex-start;
  justify-items: center;
  /* background: black; */
  padding: 0 1rem;
  position: relative;
  z-index: 8;
  flex: 1;
}
.Logo {
  /* background: black; */
  justify-items: center;
}
.Logo img {
  width: 60px;
  height: 48px;
}
.categories {
  position: fixed;
  top: 3%;
  left: 3%;
  z-index: 2000;
}
.categories .categoriesData span {
  padding: 1rem;
  font-weight: bolder;
}
.menu-links {
  position: relative;
}
.menu-cover {
  position: absolute;
  width: 250px;
  height: 600px;
  background: rgb(255, 255, 255);
  top: 100%;
  left: 10%;
  border-radius: 20px;
  z-index: 1;
  visibility: hidden;
  transform: translateX(-50%);
  opacity: 0.1;
  transition: 0.5s ease-in-out;
  /* border: 5px solid rgb(206, 203, 203); */
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.menu-links:hover .menu-cover {
  visibility: visible;
  transform: translateX(0%);
  z-index: 10 !important;
  opacity: 1;
}
.menu-links .menu-cover .menu-list ul {
  list-style: none;
  /* height: 100%; */
  display: flex;
  padding: 1rem 0;
  flex-direction: column;
  justify-content: space-between;
  /* background: #222; */
}
.menu-links .menu-cover .menu-list ul li {
  padding: 1rem 0;
  letter-spacing: 2px;
}
.menu-links .menu-cover .menu-list ul li:hover {
  background: #360c49;
  color: #ffffff;
}

.menu2-link {
  position: absolute;
  width: 1050px;
  height: 600px;
  top: 5%;

  left: 100%;
  z-index: -100 !important;
  border-radius: 10px;
  visibility: hidden;
  transform: translateY(-100%);
  /* transition: 0.5s ease-out; */
  /* border: 5px solid rgb(206, 203, 203); */
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.66);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(3.2px);
  -webkit-backdrop-filter: blur(3.2px);
  border: 1px solid rgba(255, 255, 255, 0.43);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.menu-links .menu-cover .menu-list ul li:hover .menu2-link {
  visibility: visible;
  transform: translateY(0%);
  z-index: 10 !important;
  letter-spacing: 1px;
}

.search-bar .box {
  display: flex;
  height: 40px;
  cursor: pointer;
  padding: 5px 20px;
  background: var(--body-font);
  border-radius: 30px;
  align-items: center;
  box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.1);
  z-index: 20;
}
.search-bar .box:hover input {
  width: 400px;
}
.search-bar .box input {
  width: 0;
  outline: none;
  border: none;
  font-weight: 500;
  transition: 0.8s;
  background: transparent;
}
.search-bar {
  z-index: 10;
}

.search-bar .box button {
  border: none;
  background: none;
  cursor: pointer;
}

.headerNav {
  justify-content: center;
  align-items: center;
  flex: 1;
  z-index: 10;
}

.headerNav ul {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.headerNav ul li {
  padding: 0 1rem;
  position: relative;
  align-items: center;
  text-align: center;
  justify-content: center;
}
.linkTo {
  font-size: 15px;
  font-weight: bolder;
  color: black;
  text-decoration: none;
  background-color: var(--bg);
  transition: 0.5s ease-in-out;
  border-radius: 5px;
  justify-items: center;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0.4rem;
  display: flex;
  letter-spacing: 3px;
}

.cart {
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 8;
}
.cart span {
  width: 30px;
  height: 30px;
  top: -0.8rem;
  right: 0;
  background-color: #ffffff;
  position: absolute;
  border-radius: 50%;
  font-weight: bolder;
}
.cartButton {
  cursor: pointer;
  position: relative;
  padding: 1rem;
  display: flex;
}
.cart-icon {
  z-index: 10;
  background-color: #ffffff;
  border-radius: 50%;
}

.linkTo:hover {
  background-color: #360c49;
  color: white;
}
