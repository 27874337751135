
/* ----- Product Section ----- */
.individualProduct {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin: auto;
    padding: 2.5em 0;
    gap: 1rem;
    width: 800px;
    background-color: white;
    border-radius: 5px;
    position: relative;
  }
  .individualProduct img {
    max-width: 100%;
    object-fit:contain;
    filter: drop-shadow(1px 1px 3px #a6a6a6);
  }
  
  /* ----- Photo Section ----- */
  .product__photo {
    position: relative;
  }
  
  .photo-container {
    position: absolute;
    left: -2.5em;
    display: grid;
    grid-template-rows: 1fr;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    box-shadow: 4px 4px 25px -2px rgba(0, 0, 0, 0.3);
  }
  
  .photo-main {
    border-radius: 6px 6px 0 0;
    /* background-color: #511950; */
    background: radial-gradient(#aa93a6, #fdfdfd);
  }
  .photo-main .controls {
    display: flex;
    justify-content: space-between;
    color: #5f289a;
    font-weight: bold;
    background: rgba(255, 255, 255, 0.27);
  }
  .photo-main .controls .Link{
    background: #ffffff;
    padding: 1rem;
    font-weight: bold;
  }
  .photo-main img {
    position: absolute;
    object-fit: contain;
    left: -3.5em;
    top: 2em;
    max-width: 110%;
    filter: saturate(150%) contrast(120%) hue-rotate(10deg) drop-shadow(1px 20px 10px rgba(0, 0, 0, 0.3));
  }
  
  .photo-album {
    padding: 0.7em 1em;
    border-radius: 0 0 6px 6px;
    background-color: #fff;
  }
  .photo-album ul {
    display: flex;
    justify-content: space-around;
    margin-top: 1rem;
  }
  .photo-album li {
    float: left;
    width: 55px;
    height: 55px;
    padding: 7px;
    border: 1px solid #a6a6a6;
    border-radius: 3px;
    list-style: none;
  }
  .photo-album li img{
    object-fit: contain;
  }
  
  /* ----- Informations Section ----- */
  .product__info {
    padding: 0.8em 0;
  }
  
  .title h1 {
    margin-bottom: 0.1em;
    color: #4c4c4c;
    font-size: 1.5em;
    font-weight: 900;
  }
  .title span {
    font-size: 0.7em;
    color: #a6a6a6;
  }
  
  .price {
    margin: 1.5em 0;
    color: #9b09a0;
    font-size: 1.2em;
  }
  .price span {
    padding-left: 0.15em;
    font-size: 2.9em;
  }
  
  .variant {
    overflow: auto;
    padding: 1rem 0;
  }
  .variant h3 {
    margin-bottom: 1.1em;
  }
  .variant li {
    float: left;
    width: 35px;
    height: 35px;
    padding: 3px;
    border: 1px solid transparent;
    border-radius: 3px;
    cursor: pointer;
    list-style: none;
  }
  
  .variant li:first-child, .variant li:hover {
    border: 1px solid #d928dc;
  }
  .variant li:not(:first-child) {
    margin-left: 0.1em;
  }
  
  .description {
    text-align: left;
    margin: 2em auto;
    width: 100%;
  }
  .description h3 {
    margin-bottom: 1em;
  }

  
  .buy--btn {
    padding: 1.5em 3.1em;
    border: none;
    border-radius: 7px;
    font-size: 0.8em;
    font-weight: 700;
    letter-spacing: 1.3px;
    color: #fff;
    background-color: #630463;
    box-shadow: 2px 2px 25px -7px #4c4c4c;
    cursor: pointer;
  }
  .buy--btn:active {
    transform: scale(0.97);
  }
  

 