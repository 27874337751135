.categories-main {
  padding: 0 2rem;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  justify-content: center;
  margin: 5rem auto;
}

.categories-main > :nth-child(1) {
  position: absolute;
  width: 8rem;
  left: 30%;
  top: -3rem;
}

.products-options {
  display: grid;
  width: 90%;
  grid-template-columns: 25% auto;
}

.menu {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  font-weight: 500;
  font-size: 1.3rem;
}

.menu > li {
  transition: 0.3s ease-in-out;
}
.menu > li:hover {
  cursor: pointer;
  color: rgb(35, 3, 44);
  font-weight: bolder;
  letter-spacing: 3px;
}

.product-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  height: 25rem;
  overflow-y: scroll;
  /* background: red; */
  gap: 1rem;
  justify-content: space-between;
}
.product > img {
  top: 5rem;
  width: 2rem;
  height: 4rem;
}
.product {
  width: 12rem;
  height: 8rem;
  background: #ffffff;
  position: relative;
  overflow: hidden;
  display: flex;
  border-radius: 1rem;
  padding: 1rem;
}
