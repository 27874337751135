.products-view {
  background: transparent;
  margin: 0 auto;
  width: 99.5%;
}
.products-all {
  display: flex;
}

.product-filter {
  width: 15%;
  background: rgb(255, 255, 255);
  padding: 0.5rem;
  border-radius: 15px;
}
.filter {
  position: sticky;
  top: 5rem;
  margin: auto;
}
.products-data {
  position:relative;
  border-radius: 15px;
  /* min-height: 100vh; */
  width: 85%;
  padding: 0.5rem;
  /* background: rgb(255, 255, 255); */
  margin: 0 1rem;
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: repeat(7, 1fr);
  gap: 1rem;
}
.filter ul {
  display: grid;
  grid-template-columns: repeat(1, 8rem);
  row-gap: 1rem;
}
.filter ul .filter-link {
  /* background: rgb(255, 255, 255); */
  padding: 0.5rem;
  border-radius: 5px;
  color: rgb(101, 5, 88);
  font-weight: bolder;
  letter-spacing: 1px;
  transition: 0.5s ease-in-out;
}
.filter ul .filter-link:hover {
  background: rgb(101, 5, 88);
  padding: 0.5rem;
  border-radius: 5px;
  color: rgb(255, 255, 255);
  font-weight: bolder;
  transition: 0.5s ease-in-out;
}

.eachProduct {
  background: rgb(255, 255, 255);
  padding: 1rem;
  border-radius: 15px;
  text-align: center;
  position: relative;
  overflow: hidden;
}
.eachProduct img {
  width: 40%;
  height: 40%;
}
.coverProduct {
  /* background: rgb(76, 12, 74); */
  padding: 0.5rem;
  border-radius: 10px;
  color: rgb(49, 5, 47);
  /* box-shadow: 0px 1px 8px 1px rgba(71, 25, 67, 0.75);
  -webkit-box-shadow: 0px 1px 8px 1px rgba(71, 25, 67, 0.75);
  -moz-box-shadow: 0px 1px 8px 1px rgba(71, 25, 67, 0.75); */
}
.coverProduct .name{
  margin: 1rem 0;
}
.coverProduct .name .productTitle{
  font-size:12px;
  /* background: white; */
  border-radius: 10px;
  /* color: black; */
  font-weight: bolder;
  text-transform: uppercase;
}
.coverProduct .name .productDetails{
  font-size:8px;
  /* background: white; */
  border-radius: 10px;
  /* color: black; */
  font-weight: bolder;
  margin-top: 1rem;
}
.coverProduct .productPrice{
  background: rgb(88, 8, 85);
  padding: .4rem;
  border-radius: 10px;
  color: rgb(245, 243, 243);
}
.coverProduct .buyButton{
  position: absolute;
  right: -10rem;
  top: 0;
  width: 35px;
  height: 35px;
  background: rgb(101, 5, 110);
  padding: .4rem;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
  color: rgb(255, 255, 255);
  z-index: 10;
  transition: .5s ease-in-out; 
  font-size: 20px; 
  cursor: pointer;
}

.eachProduct:hover .coverProduct .buyButton{
  right: 1rem;
}
.coverProduct .seeButton{
  position: absolute;
  right: -10rem;
  width: 35px;
  height: 35px;
  top: 20%;
  background: rgb(101, 5, 110);
  padding: .4rem;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
  color: rgb(255, 255, 255);
  z-index: 10;
  transition: .5s ease-in-out; 
  font-size: 20px; 
  cursor: pointer;
}

.eachProduct:hover .coverProduct .seeButton{
  right: 1rem;
}

.featered-products{
  padding: 1rem;
  /* background:rgb(255, 255, 255); */
  border-radius: 50px;
  margin: 1rem auto;
  width: 80%;
}
.featured-data{
  display: grid;
  grid-template-columns: repeat(6,1fr);
  gap: 1rem;

}
