footer {
  margin-top: 5rem;
  width: 100%;
  padding: 50px 0px;
  /* background-image: url(../../assets/background-footer.svg); */
  background-size: cover;

  background: linear-gradient(#ffffff, #ffffff);
  -webkit-mask-image: url("../../assets/background-footer.svg");
  mask-image: url("../../assets/background-footer.svg");
  -webkit-mask-size: cover;
  mask-size: cover;
}

.container__footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1200px;
  margin: auto;
  margin-top: 100px;
}

.box__footer {
  display: flex;
  flex-direction: column;
  padding: 40px;
}

.box__footer .logo img {
  width: 180px;
}

.box__footer .terms {
  max-width: 350px;
  margin-top: 20px;
  font-weight: 500;
  color: #7a7a7a;
  font-size: 18px;
}

.box__footer h2 {
  margin-bottom: 30px;
  color: #343434;
  font-weight: 700;
}

.box__footer a {
  margin-top: 10px;
  color: #7a7a7a;
  font-weight: 600;
}

.box__footer a:hover {
  opacity: 0.8;
}

.box__footer a .fab {
  font-size: 20px;
}

.box__copyright {
  max-width: 1200px;
  margin: auto;
  text-align: center;
  padding: 0px 40px;
}

.box__footer .wrap {
  margin: auto;
  background: #fff;
  display: flex;
  flex-direction: column;
  width: 300px;
  text-align: center;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
}

.box__footer .wrap .header {
  padding: 50px 50px 100px 50px;
  min-height: 150px;
  background: url("https://cdn0.iconfinder.com/data/icons/elpis/144/Newsletter-128.png")
    no-repeat center 130px;
}
.box__footer .header h1 {
  color: #536a89;
  text-transform: uppercase;
  font-size: 30px;
  letter-spacing: 4px;
}
.box__footer .header p {
  font-size: 13px;
  color: rgba(92, 118, 152, 0.8);
}
.box__footer .footer {
  display: flex;
  justify-content: space-between;
}
.box__footer .footer form {
  flex-grow: 1;
  display: flex;
}
.box__footer .footer input {
  background: #ffffff;
  font-size: 10px;
  border: none;
  flex-grow: 2;
  color: #5c7698;
}
.box__footer .footer input::-webkit-input-placeholder {
  color: rgba(92, 118, 152, 0.7);
  text-transform: uppercase;
}
.box__footer .footer input:focus {
  outline: none;
}
.box__footer .footer .btn-submit {
  background: #ffffff;
  border: none;
  flex-grow: 1;
  color: #5c7698;
  font-weight: 700;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 12px;
  padding: 20px;
}
.box__footer .footer .btn-submit:focus {
  outline: none;
}

.column {
  flex: 33.33%;
  padding: 5px;
}
.column1 img {
  height: 40px;
  width: 136px;
}
.column2 img {
  height: 43.5px;
  width: 134px;
}
.app p {
  white-space: pre-line;
  padding: 20px;
  justify-content: space-between;
}

.social {
  max-width: 1200px;
  margin: auto;
  text-align: center;
  padding: 0px 40px;
}
.wrapper {
  display: inline-flex;
  list-style: none;
}

.wrapper .icon {
  position: relative;
  background: #ffffff;
  border-radius: 50%;
  padding: 15px;
  margin: 10px;
  width: 50px;
  height: 50px;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper .tooltip {
  position: absolute;
  top: 0;
  font-size: 14px;
  background: #ffffff;
  color: #ffffff;
  padding: 5px 8px;
  border-radius: 5px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper .tooltip::before {
  position: absolute;
  content: "";
  height: 8px;
  width: 8px;
  background: #ffffff;
  bottom: -3px;
  left: 50%;
  transform: translate(-50%) rotate(45deg);
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper .icon:hover .tooltip {
  top: -45px;
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.wrapper .icon:hover span,
.wrapper .icon:hover .tooltip {
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
}

.wrapper .facebook:hover,
.wrapper .facebook:hover .tooltip,
.wrapper .facebook:hover .tooltip::before {
  background: #1877f2;
  color: #ffffff;
}

.wrapper .twitter:hover,
.wrapper .twitter:hover .tooltip,
.wrapper .twitter:hover .tooltip::before {
  background: #023454;
  color: #ffffff;
}

.wrapper .instagram:hover,
.wrapper .instagram:hover .tooltip,
.wrapper .instagram:hover .tooltip::before {
  background: #e4405f;
  color: #ffffff;
}

.wrapper .tiktok:hover,
.wrapper .tiktok:hover .tooltip,
.wrapper .tiktok:hover .tooltip::before {
  background: #333333;
  color: #ffffff;
}

.wrapper .youtube:hover,
.wrapper .youtube:hover .tooltip,
.wrapper .youtube:hover .tooltip::before {
  background: #cd201f;
  color: #ffffff;
}

.logo-footer{
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.box__copyright p {
  margin-top: 20px;
  color: #7a7a7a;
}
.box__copyright img {
  width: 60px;
  height: 55px;
}

.box__copyright hr {
  border: none;
  height: 1px;
  background-color: #7a7a7a;
}
